<div class="grid grid-cols-4 gap-400 md:gap-600">
  <div class="col-span-4 flex w-full flex-col gap-400 self-start md:col-span-3 lg:col-span-2 lg:col-start-2">
    <form [formGroup]="exteriorWallForm" (ngSubmit)="handleSubmit($event)" id="quote-form">
      <app-form-card [title]="'What is the exterior wall finish of your home?'" icon="lucideHouse">
        <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
          <fieldset class="form-group__fieldset">
            <legend class="sr-only">Select the exterior wall type</legend>
            <div class="grid gap-200 md:grid-cols-2">
              @for (exteriorWallOption of exteriorWallOptions; track exteriorWallOption) {
                <label class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showError" [for]="exteriorWallOption.id">
                  <div class="radio-card__header flex flex-col gap-100">
                    <div class="flex w-full items-start justify-start">
                      <img src="assets/images/{{ exteriorWallOption.image }}" alt="exterior wall image" class="rounded-200 border !border-neutral-800" />
                      <div>
                        <p class="radio-card__title m-l-200 font-medium group-has-[:checked]:font-bold">{{ exteriorWallOption.label }}</p>
                        <p class="m-t-100 m-l-200">{{ exteriorWallOption.description }}</p>
                      </div>
                    </div>
                  </div>
                  <input class="radio-card__control peer outline-none" type="radio" [id]="exteriorWallOption.id" [value]="exteriorWallOption.id" formControlName="exteriorWall" />
                </label>
              }
            </div>
          </fieldset>
          @if (showError) {
            <div class="fs-body-md fw-medium error-message flex items-center gap-100 text-warning" role="alert" data-test-id="form-error-message" aria-live="polite" aria-atomic="true">
              <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
              Please make a selection
            </div>
          }
        </div>
      </app-form-card>
    </form>
    <div class="border-100 rounded-200 bg-primary-300 px-500 py-300" data-test-id="help-text">
      <div class="flex items-start gap-300">
        <ng-icon name="lucideCircleHelp" size="24" class="!overflow-visible [&>svg]:fill-primary" aria-hidden="true"></ng-icon>
        <div class="flex flex-col gap-300">
          <div>
            <p><strong>Not sure how to answer?</strong></p>
            <p class="hidden md:block">If you'd like a little extra help, our licensed agents are here for you.</p>
            <p class="md:hidden">One of our licensed agents will be happy to help.</p>
          </div>
          <div class="hidden md:block">
            <p class="font-bold">{{ salesPhoneNumber | phone }}</p>
            <p>{{ salesPhoneHoursFormatted }}</p>
          </div>
        </div>
      </div>

      <span class="md:hidden"
        ><a href="tel:{{ salesPhoneNumber }}" data-test-id="help-text-href" class="button button--sm button--secondary mt-300 !inline-flex w-full items-center justify-center gap-100 text-center">
          <ng-icon name="lucidePhone" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
          Talk to an agent
        </a></span
      >
    </div>
  </div>
  <app-form-card class="col-span-4 self-start md:col-span-1">
    <div class="flex flex-col gap-100">
      <h2 class="flex flex-col gap-300 text-100 font-bold leading-[1.375]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary)" viewBox="0 0 32 32" class="h-[2em] w-[2em]">
          <path
            fill="var(--color-primary)"
            stroke="var(--color-neutral-800)"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10.534 26.666a12 12 0 1 0-5.2-5.2l-2.667 7.867 7.867-2.667Z"
          />
          <path fill="var(--color-primary)" d="M12.12 12a4 4 0 0 1 7.773 1.333c0 2.667-4 4-4 4" />
          <path stroke="var(--color-neutral-800)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.12 12a4 4 0 0 1 7.773 1.333c0 2.667-4 4-4 4" />
          <path fill="var(--color-primary)" d="M16 22.667h.013H16Z" />
          <path stroke="var(--color-neutral-800)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 22.667h.013" />
        </svg>
        Why are we asking for this?
      </h2>
      <p>We'll use your property info plus additional data from our trusted sources to build you the most accurate quote.</p>
    </div>
  </app-form-card>
</div>
