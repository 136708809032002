import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone } from '@ng-icons/lucide';
import * as cc from 'app/common/constants/contact-constants';
import { PhonePipe } from 'app/common/pipes/phone-number/phone.pipe';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { QuoteDraftState } from '../../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { FormCardComponent } from '../../shared/form-card.component';
import { ExteriorWallQuoteFormInput, ExteriorWallValue } from './exterior-wall.types';

@Component({
  selector: 'app-exterior-wall',
  standalone: true,
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule, PhonePipe],
  templateUrl: './exterior-wall.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({ lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone })],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/exterior-wall',
})
export class QuoteFormExteriorWallComponent extends BaseQuoteForm<ExteriorWallQuoteFormInput> implements OnInit {
  private optionsStore = inject(QuoteFormOptionsStore);
  public formDataIn: ExteriorWallQuoteFormInput = this.quoteDraftStore.exteriorWallQuoteFormSelector();

  public exteriorWallOptions = this.optionsStore.exteriorWallQuoteFormOptionsSelector();

  public formDefinition = {
    exteriorWall: new FormControl<ExteriorWallValue | undefined>(undefined, Validators.required),
  };
  public exteriorWallForm = new FormGroup(this.formDefinition);

  public salesPhoneNumber = cc.PHONE_NUMBERS.sales;
  public salesPhoneHoursFormatted = cc.PHONE_HOURS_FORMATTED.sales;

  public ngOnInit() {
    if (this.formDataIn.exteriorWall) {
      this.exteriorWallForm.controls.exteriorWall.setValue(this.formDataIn.exteriorWall);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'exteriorWall'> {
    const { controls } = this.exteriorWallForm;
    const formValues = {
      exteriorWall: controls.exteriorWall.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.exteriorWallForm.markAllAsTouched();
    if (this.exteriorWallForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public get showError() {
    const control = this.exteriorWallForm.get('exteriorWall');
    return !!control?.invalid && !!control?.touched;
  }
}
