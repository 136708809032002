import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone } from '@ng-icons/lucide';
import * as cc from 'app/common/constants/contact-constants';
import { PhonePipe } from 'app/common/pipes/phone-number/phone.pipe';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { QuoteDraftState } from '../../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { FormCardComponent } from '../../shared/form-card.component';
import { ConstructionTypeQuoteFormInput, ConstructionTypeValue } from './construction-type.types';

@Component({
  selector: 'app-construction-type',
  standalone: true,
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule, PhonePipe],
  templateUrl: './construction-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({ lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone })],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/construction-type',
})
export class QuoteFormConstructionTypeComponent extends BaseQuoteForm<ConstructionTypeQuoteFormInput> implements OnInit {
  private optionsStore = inject(QuoteFormOptionsStore);
  public formDataIn: ConstructionTypeQuoteFormInput = this.quoteDraftStore.constructionTypeQuoteFormSelector();

  public constructionTypeOptions = this.optionsStore.constructionTypeQuoteFormOptionsSelector();

  public formDefinition = {
    constructionType: new FormControl<ConstructionTypeValue | undefined>(undefined, Validators.required),
  };
  public constructionTypeForm = new FormGroup(this.formDefinition);

  public salesPhoneNumber = cc.PHONE_NUMBERS.sales;
  public salesPhoneHoursFormatted = cc.PHONE_HOURS_FORMATTED.sales;

  public ngOnInit() {
    if (this.formDataIn.constructionType) {
      this.constructionTypeForm.controls.constructionType.setValue(this.formDataIn.constructionType);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'constructionType'> {
    const { controls } = this.constructionTypeForm;
    const formValues = {
      constructionType: controls.constructionType.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.constructionTypeForm.markAllAsTouched();
    if (this.constructionTypeForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public get showError() {
    const control = this.constructionTypeForm.get('constructionType');
    return !!control?.invalid && !!control?.touched;
  }
}
